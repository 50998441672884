import * as dom from './dom-helpers';
import * as ld from 'lodash';

export class Accs {
    private accSelector: string;
    private currentExpanded: number;
    private tabsElement: HTMLElement;
    
    constructor(accSelector?) {
        this.accSelector = accSelector || '.accs';
        this.currentExpanded = null;
        this.tabsElement = document.querySelector(this.accSelector);
        this.iniAndListenTabs();
    }

    public iniAndListenTabs () {
    
        const tabs = this.tabsElement.getElementsByClassName("accordion-item");
        for (let i = 0; i < tabs.length; i++) {
            tabs[i].addEventListener('click', (event) => {
                this.changeCurrent(tabs[i]);
            }, false);
        }
    }

    private changeCurrent(newCurrentExpandedElement) {
        if(this.currentExpanded !== null) {
            dom.removeClass(this.currentExpanded, 'expanded')
        }
        if(ld.isEqual(this.currentExpanded, newCurrentExpandedElement)) {
            this.currentExpanded = null;
            return;
        }
        dom.addClass(newCurrentExpandedElement, 'expanded');
        this.currentExpanded = newCurrentExpandedElement;
    }
}


export function show(el) {
    el.style.display = 'block';
}

export function hide(el) {
    el.style.display = 'none';
}

export function addClass(el, name) {
    el.classList.add(name);
}

export function removeClass(el, name) {
    el.classList.remove(name);
}
import './scroll';
import { Tabs } from './tabs';
import { Accs } from './accordion';
import { fromEvent } from 'rxjs';
import { tap, debounceTime } from 'rxjs/operators';
import * as dom from './dom-helpers';
import * as ld from 'lodash';

window.addEventListener('load', function () {
    const tabs = new Tabs();
    const accs = new Accs();
    const accs2 = new Accs('.acc2018');
    const accs3 = new Accs('.acc2017');
    const accs4 = new Accs('.accs3');

    const mobileMenuItems = document.getElementsByClassName('menu__item');
    // const mobileMenu = document.querySelector('.menu__box');
    const menuToggle = document.getElementById('menu__toggle');

    for (var i = 0; i < mobileMenuItems.length; i++) {
        mobileMenuItems[i].addEventListener('click', () => {
            // console.log('mobileMenu: ', mobileMenu);
            // mobileMenu.style.visibility = 'hidden';
            // mobileMenu.style.left = '-100%';
            menuToggle.checked = false;
        }, false);
    }

    const menuSections = document.querySelectorAll('[id^=ancor-]');
    const sections = {};
    let index: any = 0;

    Array.prototype.forEach.call(menuSections, function (e) {
        sections[e.id] = getCoords(e).top - 100;
    });

    let currentMenuItem = null;

    fromEvent(window, 'scroll').pipe(
        debounceTime(100),
        tap((event) => {
            const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
            let currentMenuItem = null;
            for (index in sections) {
                const elementById = document.querySelector('a[href*=' + index + ']');
                if (elementById) {
                    dom.removeClass(elementById.parentNode, 'active-menu-item');
                }
                if (sections[index] <= scrollPosition) {
                    currentMenuItem = elementById;
                }
            }
            if (currentMenuItem) {
                dom.addClass(currentMenuItem.parentNode, 'active-menu-item');
            }
        })
    ).subscribe();

    function getCoords(elem) {
        var box = elem.getBoundingClientRect();
        return {
          top: box.top + pageYOffset,
          left: box.left + pageXOffset
        };
    }

});

// (function () {
//     'use strict';

//     var section = document.querySelectorAll("[id^=ancor-]");
//     var sections = {};
//     var i = 0;

//     Array.prototype.forEach.call(section, function (e) {
//         sections[e.id] = e.offsetTop;
//     });

//     window.onscroll = function () {
//         var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

//         for (i in sections) {
//             if (sections[i] <= scrollPosition) {
//                 console.log('scrollPosition: ', scrollPosition);
//                 document.querySelector('.active').setAttribute('class', ' ');
//                 document.querySelector('a[href*=' + i + ']').setAttribute('class', 'active');
//             }
//         }
//     };
// })();

import SmoothScroll = require('smooth-scroll');

const scroll = new SmoothScroll('a[href*="#"]', {
 
    // Selectors
    ignore: '[data-scroll-ignore]', // Selector for links to ignore (must be a valid CSS selector)
    topOnEmptyHash: true, // Scroll to the top of the page for links with href="#"
    header: '.menu-horizontal',
 
    // Speed & Duration
    speed: 400, // Integer. Amount of time in milliseconds it should take to scroll 1000px
    speedAsDuration: false, // If true, use speed as the total duration of the scroll animation
    durationMax: null, // Integer. The maximum amount of time the scroll animation should take
    durationMin: null, // Integer. The minimum amount of time the scroll animation should take
    clip: true, // If true, adjust scroll distance to prevent abrupt stops near the bottom of the page
 
    // Easing
    easing: 'easeInOutQuart', // Easing pattern to use
 
    // History
    updateURL: true, // Update the URL on scroll
    popstate: true, // Animate scrolling with the forward/backward browser buttons (requires updateURL to be true)
 
    // Custom Events
    emitEvents: true // Emit custom events
 
});

// Log scroll events
const logScrollEvent = function (event) {
 
    // // The event type
    // console.log('type:', event.type);
 
    // // The anchor element being scrolled to
    // console.log('anchor:', event.detail.anchor);
 
    // // The anchor link that triggered the scroll
    // console.log('toggle:', event.detail.toggle);
 
};
 
// Listen for scroll events
document.addEventListener('scrollStart', logScrollEvent, false);
document.addEventListener('scrollStop', logScrollEvent, false);
document.addEventListener('scrollCancel', logScrollEvent, false);
import * as dom from './dom-helpers';

export class Tabs {
    private tabSelector: string;
    private currentTab: number;
    private tabsElement: HTMLElement;
    
    constructor(tabSelector?) {
        this.tabSelector = tabSelector || '.tabs';
        this.currentTab = 0;
        this.tabsElement = document.querySelector(this.tabSelector);
        this.iniAndListenTabs();
    }

    public iniAndListenTabs () {
        this.changeCurrent(0);
    
        const tabs = this.tabsElement.getElementsByClassName("tab-selector");
        for (let i = 0; i < tabs.length; i++) {
            tabs[i].addEventListener('click', (event) => {
                const id = tabs[i].getAttribute("data-tab-id");
                this.changeCurrent(id);
            }, false);
        }
    }

    private changeCurrent(newCurrent) {
        if(this.currentTab !== null) {
            const currentTabSelector = this.getTabSelectorById(this.currentTab);
            const currentTabContent  = this.getContentById(this.currentTab);
            dom.removeClass(currentTabSelector, 'current');
            dom.removeClass(currentTabContent, 'current');
        }
        const newTabSelector = this.getTabSelectorById(newCurrent);
        const newTabContent  = this.getContentById(newCurrent);
        dom.addClass(newTabSelector, 'current');
        dom.addClass(newTabContent, 'current');
        this.currentTab = newCurrent;
    }
    
    private getTabSelectorById(id) {
        return this.tabsElement.querySelector(`.tab-selector[data-tab-id="${id}"]`);
    }
    
    private getContentById(id) {
        return this.tabsElement.querySelector(`.tab-content[data-content-id="${id}"]`);
    }
}

